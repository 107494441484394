
import { Options, Vue, Watch } from "vue-property-decorator";
import canjiandanwei from "./leadercanjiandanwei.vue";
import leadermanger from "./leadermanger.vue";
import dutytime from "./dutytime.vue";
import * as api from "@/api/leadercheck.ts";
import File from "@/api/file";

@Options({
  components: {
    canjiandanwei,
    leadermanger,
    dutytime,
  },
})
export default class extends Vue {
  private flag = false;
  private submitform = {
    address: "",
    companyId: "",
    content: "",
    problem: "",
    otherCreater: "",
    entourage: "",
    resolution: "",
    changePersonId: "",
    limitDate: "",
    problemFileIds: "",
    projectId: "",
  };
  private uploader: any[] = [];

  @Watch("uploader", { deep: true })
  watchFile(files: any) {
    let obj = files.find((file: any) => {
      return file.status === "uploading";
    });
    if (!obj) {
      this.flag = false;
    } else {
      this.flag = true;
    }
  }

  protected selectCompany(val: any) {
    this.submitform.companyId = val;
  }

  protected selectPerson(val: any) {
    this.submitform.changePersonId = val;
  }

  protected selectLimit(val: any) {
    this.submitform.limitDate = val;
  }

  protected afterRead(files: any) {
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file: any) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile(
        "photographmobile/",
        file.file.name,
        file.file,
        true
      ).then((res: any) => {
        file.status = "done";
        file.id = res.data.id;
      });
    });
  }

  protected onSubmit() {
    let arr = "";
    this.uploader.map((item) => {
      arr += "," + item.id;
    });
    this.submitform.projectId = this.$store.state.project.projectinfo.projectId;
    this.submitform.problemFileIds = arr.substr(1, arr.length - 1);
    api.projectLeaderChecksave(this.submitform).then((res: any) => {
      if (res.code === 0) {
        this.$toast.success({
          message: res.msg,
          forbidClick: true,
          onClose: () => {
            this.$router.go(-1);
          },
        });
      } else {
        this.$toast.fail(res.msg);
      }
    });
  }
}
