
import { Options, Vue } from "vue-property-decorator";
import { parseTime } from "@/utils";

@Options({})
export default class extends Vue {
  private currentDay = new Date();
  private minDate = new Date(2020, 0, 1);
  private show = false;
  private currentDate: any = null;

  pickerconfirm(val: any) {
    this.currentDate = parseTime(val, "{y}年{m}月{d}日");
    this.$emit("selectLimit", parseTime(val, "{y}-{m}-{d} 00:00:00"));
    this.show = false;
  }
}
