
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/leadercheck.ts";

@Options({})
export default class extends Vue {
  private chargeName = "";
  private selectCompany = false;
  private companyList: any[] = [];
  private company = "";
  private finished = false;
  private refreshing = false;
  private loading = false;

  @Watch("company")
  onCompanyChanged(newValue: string, oldValue: string) {
    if (newValue === "") {
      this.chargeName = "";
      this.$emit("selectCompany", "");
    }
    this.onRefresh();
  }

  protected cancel() {
    this.selectCompany = false;
  }

  protected onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.companyList = [];
        this.refreshing = false;
      }
      api
        .projectDeptlistProjectCompany({
          projectId: this.$store.state.project.projectinfo.projectId,
          name: this.company,
        })
        .then((res: any) => {
          this.companyList = res.data.records;
          this.loading = false;
          this.finished = true;
          this.refreshing = false;
        });
    }, 1000);
  }

  protected selectCompanyEvent(item: any) {
    this.chargeName = item.name;
    this.company = item.name;
    // this.deptId = item.deptId
    this.selectCompany = false;
    this.$emit("selectCompany", item.deptId);
  }

  // 参建单位上拉刷新
  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }
}
